/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import HeroReference from 'components/elements/references/HeroReference'
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/shared/GravityForm'
import ImageBackground from 'components/shared/ImageBackground'

// Blog
import BlogikReferenties from 'components/related/Referenties'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'


const Title = styled.div`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size['100']};
  line-height: 100px;
  color: ${props => props.theme.color.text.secondary};

  @media screen and (max-width: 1199px) {
    font-size: ${props => props.theme.font.size.xxxl};
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const FormContent = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 50px 10px 50px 10px;
  text-align: center;

  p {
    color: ${props => props.theme.color.text.main};
  }

  h2 {
    color: ${props => props.theme.color.text.main};
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xxxl};
    text-align: center;
  }
`

const FormContentContainer = styled.div`
  background-color: ${props => props.theme.color.face.secondary};
  height: 100%;
  width: 100%;
  position: absolute;
`

const StyledImageBackground = styled(ImageBackground)`
  width: 100%;
  height: 100%;
  opacity: 0.1 !important;
`

const FormContainer = styled.div`
  background-color: ${props => props.theme.color.face.main};
  height: 100%;
  width: 100%;
  padding: 50px 40px 50px 40px;
`

const PageTemplate = ({
  data: {
    page: { title, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroReference fields={acf.banner} />

      <div className="d-md-block d-none" style={{ height: '100px' }} />

      <div className="container">
        <div className="row justify-content-center">
          <FormContainer className="col-md-6">
            <GravityForm id={2} title={title} />
          </FormContainer>
          <div className="col-md-3 px-0">
            <FormContentContainer>
              <StyledImageBackground src={acf.banner.background} aboveFold />
              <FormContent>
                <h2>Solliciteer direct</h2>
                <p>OF</p>
                <ButtonDefault to="/contact">
                  Neem contact met ons op
                </ButtonDefault>
              </FormContent>      
            </FormContentContainer>
            
          </div>
        </div>
      </div>

      <div className="d-md-block d-none" style={{ height: '100px' }} />

      <div className="container px-lg-5">
        <div className="px-md-5">
          <Blogik
            settings={{
              limit: 2,
              postType: 'referenties'
            }}
          >
            <BlogConsumer>
              {({ hasPosts, posts }) => {
                return (
                  <div>
                    {hasPosts && (
                      <>
                        <Title className="pb-md-3 pb-2">Bekijk ook</Title>
                        <BlogikReferenties posts={posts} />
                      </>
                    )}

                    {!hasPosts && (
                      <ParseContent content="Geen resultaten" />
                    )}
                  </div>
                )
              }}
            </BlogConsumer>
          </Blogik>
        </div>
      </div>
      
      <div className="py-2" />
      <div className="py-5" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpVacatures(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          images {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                    src
                  }
                }
              }
            }
          }
          description
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
